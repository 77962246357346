// if you need to add custom js put it in here
$(window).load(function () {
    $('.HButton').bootstrapSwitch();
    $('.about-content-wrapper').matchHeight();
    $('.blogTitle').matchHeight();
    $('.pf-recent-related-product-item.product-match-height').matchHeight();
    $('.project-column .productname').matchHeight();
    $('.product-info-match-height').matchHeight();
    setSelectedVariantStockCount($("#variant-select option:selected").data("variant-stock")); 
});

function setSelectedVariantStockCount(stock) {
    $('#selectedVariantStockCount').html('');//place to store the selected variant's stock or the enquire button 
    if (stock < 1 || $("#variant-select option:selected").data("variant-show-stock") == false) {
        $('#selectedVariantStockCount').append('<a class=" pf-btn pf-btn-sm pf-btn-primary variant-enquire" data-variant-name="' + $('#variant-select option:selected').text().replace(/"/g, "&quot;") + '" id="enquireAboutAvailability">Enquire about availability</a>');
    }
    else if ($("#variant-select option:selected").data("variant-default-option")) {//if the default option is shown do not display stock text

    }
    else {
        $('#selectedVariantStockCount').append('Availability: ' + (stock > 10 ? '10+' : stock) + ' In Stock');
    }
}​

$('#variant-select').on('change', function () {
    var stock = $("#variant-select option:selected").data("variant-stock");
    setSelectedVariantStockCount(stock);
});
$(document).on('click', '#enquireAboutAvailability', function (e) {
    loadVariantModal();
});
    


